<template>
  <b-card id="addAccountWrapper">
    <validation-observer ref="editAccount" #default="{ invalid }">
      <b-form @submit.prevent="updateAccount(accountID)">
        <b-container>
          <b-row class="justify-content-md-center">
            <b-col cols="12" md="2">
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="outline-warning"
                to="/myprofile/accounts"
                v-b-tooltip.hover
                title="Go Back"
              >
                <feather-icon icon="ArrowLeftCircleIcon" size="20" />
              </b-button>
            </b-col>
            <b-col cols="12" md="10">
              <h1>Edit the account</h1>
            </b-col>
          </b-row>
          <hr />

          <b-row class="justify-content-md-center">
            <b-col  cols="12" md="10" class="ml-md-5">
              <b-form-group label="Account Name" label-for="d-name">
                <validation-provider
                  #default="{ errors }"
                  name="Account name"
                  rules="required"
                >
                  <b-form-input
                    id="d-name"
                    v-model="account_name"
                    placeholder="Account Name"
                    autofocus
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- input for the account's identifiant  -->
          <!-- <b-row class="justify-content-md-center">
            <b-col  cols="12" md="10" class="ml-md-5">
              <b-form-group label="Account Id" label-for="d-id">
                <validation-provider
                  #default="{ errors }"
                  name="Account ID"
                >
                  <b-form-input
                    id="d-id"
                    v-model="account_id"
                    placeholder="Account ID"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger account_id_error">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->
          <b-row class="justify-content-md-center">
            <b-col cols="12" md="10" class="ml-md-5">
              <b-form-group label="Account Link" label-for="d-link">
                <validation-provider
                  #default="{ errors }"
                  name="Account Link"
                  rules="url"
                >
                  <b-form-input
                    id="d-link"
                    type="url"
                    v-model="account_link"
                    placeholder="Account Link"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="justify-content-md-center">
            <b-col md="6">
              <b-button
                type="submit"
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="outline-warning"
                class="mx-auto w-50 d-block"
                :disabled="invalid"
              >
                Update
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";

import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  VBTooltip,
  BButton,
  BContainer,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BContainer,
    BFormInput,
    BFormGroup,
    BFormFile,
    BForm,
    VBTooltip,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      account_name: null,
      account_id: null,
      account_link: null,
      data: {},
      // validation rules
      required,
      regex,
      url,
    };
  },
  computed: {
    accountID() {
      return this.$store.state.appsManager.accountID;
    },
  },
  created() {
    this.fillAccountFields();
  },
  methods: {
    fillAccountFields() {
      axios.get(`/get-account/${this.accountID}`).then((response) => {
        if (response.data.status === "success") {
          this.account_name = response.data.account.account_name;
          this.account_id = response.data.account.account_id
          this.account_link = response.data.account.account_link;
        } else if (response.data.status === "failed") {
          this.showToast(
            "warning",
            "AlertOctagonIcon",
            "Alert",
            response.data.message
          );
        }
      });
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async updateAccount(id) {
      if (!this.account_name) {
        this.showToast(
          "warning",
          "AlertOctagonIcon",
          "Alert",
          `You need to fill a field so as updating it.`
        );
        return;
      }
      await axios
        .post("/update-account", {
          id: id,
          account_link: this.account_link,
          account_name: this.account_name,
        })
        .then(({ data }) => {
          if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            router.push({ name: "accounts" });
            // this.$refs.resetButton.click();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
#addAccountWrapper{
  width: 60% !important;
  display: block;
  margin-inline: auto;
  margin-top: 3rem;
  box-shadow: 8px 6px 7px rgba(0, 0, 0, 0.2) !important;
}
@media (max-width: 560px) {
  #addAccountWrapper {
    width: 100% !important;

  }
}
</style>
